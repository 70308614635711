
import { GatewayType } from "@fundraisingbureauet/admin-typescript-client/models/GatewayType";

export default function gatewayTypeToUserText(gatewayType: string): string {
  switch (gatewayType) {
    case GatewayType.EPAY:
      return "Card w/ ePay (Bambora)";
    case GatewayType.DIBS:
      return "Card w/ DIBS Easy";
    case GatewayType.MOBILEPAYSUBSCRIPTIONS:
      return 'MobilePay Subscriptions';
    case GatewayType.BETALINGSSERVICE:
      return 'Betalingsservice';
    case GatewayType.SMSINBOUND:
      return 'SMS Inbound';
    case GatewayType.SMSINBOUNDSANDBOX:
      return 'SMS Inbound (Sandbox)';
    case GatewayType.TEST:
      return 'Test';
    default:
      return gatewayType;
  }
}
